@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
}

main {
  min-height: 100vh;
}

:root {
  /* an opacity version of the primary colour, used for the scrollbar */
  --scrollbar-colour: #9ca3af;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-colour) transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  opacity: 0.2;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-colour);
  border-radius: 14px;
}

.table th,
.table td {
  @apply !p-4;
  vertical-align: middle;
}

.btn-citation {
  @apply align-text-top ml-1 border border-blueGrey-100 bg-gray-50 text-blueGrey-400 rounded-full px-[6px] py-[2px] text-[10px] leading-[14px] w-fit hover:bg-gray-800 transition-all hover:text-white;
}

.btn-citation span[data-lexical-text="true"] {
  /* Reset text-related properties */
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

:target,
.highlight {
  background-color: #ffff99;
  width: fit-content;
  transition: background-color 0.5s ease;
}
